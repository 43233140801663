export const enum ScaleRatio {
  /** 1 */
  Unison = 1,
  /** 1.067 */
  MinorSecond = 16 / 15,
  /** 1.125 */
  MajorSecond = 9 / 8,
  /** 1.2 */
  MinorThird = 6 / 5,
  /** 1.25 */
  MajorThird = 5 / 4,
  /** 1.333 */
  PerfectFourth = 4 / 3,
  /** 1.4 */
  DiminishedFifth = 7 / 5,
  /** 1.5 */
  PerfectFifth = 3 / 2,
  /** 1.6 */
  MinorSixth = 8 / 5,
  /** 1.667 */
  MajorSixth = 5 / 3,
  /** 1.75 */
  MinorSeventh = 7 / 4,
  /** 1.875 */
  MajorSeventh = 15 / 8,
  /** 2 */
  Octave = 2,
}

export type ScaleUnit = 'em' | 'rem' | 'px';

export interface ScaleOptions {
  ratio?: ScaleRatio;
  unit?: ScaleUnit;
  levels?: number;
}

const Scale = (options?: ScaleOptions) => {
  const trailingZeros = /\.?0+$/;
  const { ratio = ScaleRatio.MajorThird, unit = 'rem', levels = 8 } = options ?? {};

  return (interval: number) =>
    `${Math.pow(ratio, interval).toFixed(3).replace(trailingZeros, '')}${unit}`;
};

export default Scale;
