import { MDXProvider } from '@mdx-js/react';
import { Box, Link, Typography } from '@mui/joy';
import { Link as GatsbyLink } from 'gatsby';
import React, { PropsWithChildren } from 'react';

const MarkdownProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <MDXProvider
    components={{
      Box,
      a: ({ href, children }) => (
        <Link component={GatsbyLink} to={href!}>
          {children}
        </Link>
      ),
      p: ({ children }) => (
        <Typography level="body-lg" mb={1}>
          {children}
        </Typography>
      ),
      h1: ({ children }) => (
        <Typography level="h1" component="h1" mb="1.4rem">
          {children}
        </Typography>
      ),
      h2: ({ children }) => (
        <Typography level="h2" component="h2" mb={1}>
          {children}
        </Typography>
      ),
      blockquote: ({ children }) => (
        <Box sx={{ pl: 1, borderLeft: '4px solid', borderLeftColor: 'neutral.400' }}>
          {children}
        </Box>
      ),
    }}
  >
    {children}
  </MDXProvider>
);

export default MarkdownProvider;
