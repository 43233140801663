import { CssBaseline, CssVarsProvider } from '@mui/joy';
import { GatsbySSR } from 'gatsby';
import React from 'react';
import IdentityProvider from './components/providers/IdentityProvider';
import MarkdownProvider from './components/providers/MarkdownProvider';
import SettingsProvider from './components/providers/SettingsProvider';
import theme from './styles/theme';

const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <CssVarsProvider theme={theme}>
    <SettingsProvider>
      <CssBaseline />
      <MarkdownProvider>
        <IdentityProvider>{element}</IdentityProvider>
      </MarkdownProvider>
    </SettingsProvider>
  </CssVarsProvider>
);

export default wrapRootElement;
