import { Box, Container, Link, Stack, Typography } from '@mui/joy';
import { Link as GatsbyLink, PageProps } from 'gatsby';
import React from 'react';
import { FontFamily, Weight } from '../styles/theme';

export interface HeaderProps {
  location?: PageProps['location'];
}

const navigationLinks = [
  {
    name: 'Writing',
    link: '/writing',
  },
  {
    name: 'Projects',
    link: '/creating',
  },
];

const Header: React.FC<HeaderProps> = ({ location }) => {
  return (
    <>
      <Container maxWidth="xl">
        <Stack
          component="header"
          direction={['column', 'row']}
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          pt={3}
          pb={2}
          mb="calc(5vw - 20px)"
        >
          <Link
            level="h2"
            component={GatsbyLink}
            to="/"
            underline="none"
            fontFamily={FontFamily.StyleScript}
            fontWeight={Weight.xs}
            fontSize="xl4"
            sx={{
              opacity: 1,
              transform: 'translateY(6px)',
              '&:hover': {
                opacity: 0.7,
              },
            }}
          >
            Mike Fowler
          </Link>

          <Box>
            <Stack direction="row" gap={2}>
              {navigationLinks?.map(({ name, link }) => (
                <Link key={link} component={GatsbyLink} to={link} color="neutral" fontSize="lg">
                  <Typography fontFamily="display">{name}</Typography>
                </Link>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default Header;
