exports.components = {
  "component---src-components-templates-article-template-tsx-content-file-path-content-writing-diabetes-miraculum-index-mdx": () => import("./../../../src/components/templates/ArticleTemplate.tsx?__contentFilePath=/opt/build/repo/content/writing/diabetes-miraculum/index.mdx" /* webpackChunkName: "component---src-components-templates-article-template-tsx-content-file-path-content-writing-diabetes-miraculum-index-mdx" */),
  "component---src-components-templates-article-template-tsx-content-file-path-content-writing-heart-opener-a-playlist-for-mdma-index-mdx": () => import("./../../../src/components/templates/ArticleTemplate.tsx?__contentFilePath=/opt/build/repo/content/writing/heart-opener-a-playlist-for-mdma/index.mdx" /* webpackChunkName: "component---src-components-templates-article-template-tsx-content-file-path-content-writing-heart-opener-a-playlist-for-mdma-index-mdx" */),
  "component---src-components-templates-documentation-template-tsx-content-file-path-content-documentation-medicine-song-resources-md": () => import("./../../../src/components/templates/DocumentationTemplate.tsx?__contentFilePath=/opt/build/repo/content/documentation/Medicine Song Resources.md" /* webpackChunkName: "component---src-components-templates-documentation-template-tsx-content-file-path-content-documentation-medicine-song-resources-md" */),
  "component---src-components-templates-documentation-template-tsx-content-file-path-content-documentation-nutrition-facts-md": () => import("./../../../src/components/templates/DocumentationTemplate.tsx?__contentFilePath=/opt/build/repo/content/documentation/Nutrition Facts.md" /* webpackChunkName: "component---src-components-templates-documentation-template-tsx-content-file-path-content-documentation-nutrition-facts-md" */),
  "component---src-components-templates-documentation-template-tsx-content-file-path-content-documentation-pizza-dough-md": () => import("./../../../src/components/templates/DocumentationTemplate.tsx?__contentFilePath=/opt/build/repo/content/documentation/Pizza Dough.md" /* webpackChunkName: "component---src-components-templates-documentation-template-tsx-content-file-path-content-documentation-pizza-dough-md" */),
  "component---src-pages-creating-tsx": () => import("./../../../src/pages/creating.tsx" /* webpackChunkName: "component---src-pages-creating-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-theme-tsx": () => import("./../../../src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */)
}

