import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface Settings {
  showVerticalRhythm: boolean;
}

const defaultSettings = {
  showVerticalRhythm: false,
};

interface SettingsContextValue {
  settings: Settings;
  updateSettings: (settings: Partial<Settings>) => void;
}

export const SettingsContext = createContext<SettingsContextValue>({
  settings: defaultSettings,
  updateSettings: () => {},
});

export interface SettingsProviderProps extends PropsWithChildren {}

const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<Settings>(defaultSettings);

  const updateSettings = useCallback((value: Partial<Settings>) => {
    setSettings((prev) => ({ ...prev, ...value }));
  }, []);

  const value = useMemo(() => ({ settings, updateSettings }), [settings]);

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export const useSettings = (): SettingsContextValue => useContext(SettingsContext);

export default SettingsProvider;
