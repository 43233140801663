import { Box } from '@mui/joy';
import { keyframes, SxProps } from '@mui/system';
import palettes from 'nice-color-palettes';
import React, { CSSProperties } from 'react';

export interface AnimatedGradientProps {
  speed?: CSSProperties['animationDuration'];
}

const spin = keyframes`
  0% {
    --a: 0deg;
  }
  100% {
    --a: 360deg;
  }
`;

const commonStyles: SxProps = {
  position: 'absolute',
  inset: 0,
};

const getPalette = (preselectedIndex: number | null = null) => {
  let index: number;

  if (preselectedIndex === null) {
    index = Math.floor(Math.random() * palettes.length);
  } else {
    index = preselectedIndex;
  }

  const palette = palettes[index] as string[];

  return palette;
};

const AnimatedGradient: React.FC<AnimatedGradientProps> = ({ speed = '20s' }) => {
  const [color1, color2, color3, color4] = ['#f67280', '#f8b195', '#c06c84', '#6c5b7b'];

  return (
    <Box
      sx={{
        position: 'fixed',
        inset: 0,
        zIndex: -1,
        pointerEvents: 'none',
      }}
    >
      <Box
        sx={{
          ...commonStyles,
          animation: `${spin} ${speed} ease infinite`,
          animationDirection: 'alternate-reverse',
          backgroundPosition: 'center',
          backgroundImage: `
            repeating-conic-gradient(
              from var(--a),
              ${color1} 0%,
              ${color2} 16.666%,
              ${color3} 33.333%,
              ${color4} 50%,
              ${color3} 66.666%,
              ${color2} 83.333%,
              ${color1} 100%
            )
          `,
        }}
      />
    </Box>
  );
};

export default AnimatedGradient;
