import { Box } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import { PageProps } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import AnimatedGradient from '../AnimatedGradient';
import Footer from '../Footer';
import Header from '../Header';
import { useSettings } from '../providers/SettingsProvider';

export interface LayoutProps<DataType = object, PageContextType = object>
  extends PropsWithChildren,
    Omit<PageProps, 'children'> {
  showVerticalRhythm?: boolean;
}

const verticalRhythm: SxProps = {
  backgroundImage: 'linear-gradient(rgba(50, 50, 50, 0.2) 1px, transparent 1px)',
  backgroundSize: '100% 1rlh',
};

const BLUR = 'blur(0px)';

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  const { settings } = useSettings();

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        ...(settings.showVerticalRhythm ? verticalRhythm : {}),
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minHeight: 'calc(100vh - 2rlh)',
          m: 'var(--layout-inset)',
          mb: 0,
          background: 'white',
          borderRadius: '3px 3px 0 0',
          backdropFilter: BLUR,

          '&::after': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            inset: 'calc(var(--layout-inset) / -2)',
            bottom: 0,
            mixBlendMode: 'soft-light',
            borderRadius: '5px 5px 0 0 ',
            background: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <Header location={location} />

        <Box component="main" sx={{ flex: 1 }}>
          {children}
        </Box>

        <Footer location={location} />
      </Box>

      <AnimatedGradient />
    </Box>
  );
};

export default Layout;
