import { CssVarsThemeOptions, extendTheme } from '@mui/joy';
import { ColorSystemOptions } from '@mui/joy/styles/extendTheme';
import { DefaultColorScheme } from '@mui/joy/styles/types';
import generatePalette from './generatePalette';
import Scale, { ScaleRatio } from './scale';

const scale = Scale({
  ratio: ScaleRatio.MajorThird,
});

const enum Typeface {
  Alegreya = 'Alegreya Variable, serif',
  Montez = 'Montez, cursive',
  SourceSans = 'Source Sans 3 Variable, sans-serif',
  Montserrat = 'Montserrat Variable, sans-serif',
  WorkSans = 'Work Sans Variable, sans-serif',
  Merriweather = 'Merriweather, serif',
  StyleScript = 'Style Script, cursive',
  Palanquin = 'Palanquin, sans-serif',
  Kanit = 'Kanit, sans-serif',
}

export const FontFamily = {
  Montserrat: 'montserrat',
  SourceSans: 'sourceSans',
  Montez: 'montez',
  Alegreya: 'alegreya',
  WorkSans: 'workSans',
  Merriweather: 'merriweather',
  StyleScript: 'styleScript',
  Palanquin: 'palanquin',
  Kanit: 'kanit',
};

export const enum Weight {
  xs = 200,
  sm = 300,
  md = 400,
  lg = 700,
  xl = 600,
}

declare module '@mui/joy/styles' {
  interface Palette {
    gradient: {
      link: string;
      background: string;
    };
  }

  interface TypographySystemOverrides {}

  interface FontFamilyOverrides {
    // By style…
    handwritten: true;

    // By typeface name…
    alegreya: true;
    montez: true;
    sourceSans: true;
    montserrat: true;
    workSans: true;
    merriweather: true;
    styleScript: true;
    palanquin: true;
    kanit: true;
  }
}

const typography: CssVarsThemeOptions = {
  fontFamily: {
    // By style…
    display: Typeface.Palanquin,
    body: Typeface.Merriweather,
    handwritten: Typeface.Montez,

    // By typeface name…
    [FontFamily.Alegreya]: Typeface.Alegreya,
    [FontFamily.Montez]: Typeface.Montez,
    [FontFamily.SourceSans]: Typeface.SourceSans,
    [FontFamily.Montserrat]: Typeface.Montserrat,
    [FontFamily.WorkSans]: Typeface.WorkSans,
    [FontFamily.Merriweather]: Typeface.Merriweather,
    [FontFamily.StyleScript]: Typeface.StyleScript,
    [FontFamily.Palanquin]: Typeface.Palanquin,
    [FontFamily.Kanit]: Typeface.Kanit,
  },
  lineHeight: {
    xl: '1rlh',
    lg: '1rlh',
    md: '0.8rlh',
    sm: '0.65rlh',
    xs: '0.8rlh',
  },
  fontSize: {
    xl4: scale(4),
    xl3: scale(3),
    xl2: scale(2),
    xl: scale(1),
    lg: scale(0),
    md: scale(-1),
    sm: scale(-2),
    xs: scale(-3),
  },
  spacing: (absolute: number) => `${absolute / 2}rlh`,
  fontWeight: {
    xs: Weight.xs,
    sm: Weight.sm,
    md: Weight.md,
    lg: Weight.lg,
    xl: Weight.xl,
  },
};

const primaryLightPalette = generatePalette(['#f8b195', '#f67280', '#6c5b7b', '#355c7d']);

const colorSchemes: Partial<Record<DefaultColorScheme, ColorSystemOptions>> = {
  light: {
    palette: {
      primary: primaryLightPalette,
      gradient: {
        link: `linear-gradient(to bottom right, ${primaryLightPalette[300]}, ${primaryLightPalette[500]})`,
        background: `repeating-conic-gradient(
              from var(--a),
              ${primaryLightPalette[50]} 0%,
              ${primaryLightPalette[300]} 16.666%,
              ${primaryLightPalette[600]} 33.333%,
              ${primaryLightPalette[800]} 50%,
              ${primaryLightPalette[600]} 66.666%,
              ${primaryLightPalette[300]} 83.333%,
              ${primaryLightPalette[50]} 100%
            )`,
      },
    },
  },
  dark: {},
};

const theme = extendTheme({
  ...typography,
  colorSchemes,
});

export default theme;
