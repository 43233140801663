import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import netlifyIdentity from 'netlify-identity-widget';

interface IdentityContextValue {
  isLoggedIn: boolean;
  user: netlifyIdentity.User | null;
  signIn: () => void;
  signOut: () => void;
}

const IdentityContext = createContext<IdentityContextValue>({
  isLoggedIn: false,
  user: null,
  signIn: () => {},
  signOut: () => {},
});

export interface IdentityProviderProps extends PropsWithChildren {}

const IdentityProvider: React.FC<IdentityProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(netlifyIdentity.currentUser() !== null);
  const [user, setUser] = useState<netlifyIdentity.User | null>(netlifyIdentity.currentUser());

  const signIn = useCallback(() => {
    setIsLoggedIn(true);
    netlifyIdentity.open();
    netlifyIdentity.on('login', (user) => {
      setUser(user);
      netlifyIdentity.close();
    });
  }, []);

  const signOut = useCallback(() => {
    setIsLoggedIn(false);
    netlifyIdentity.logout();
    netlifyIdentity.on('logout', () => {
      setUser(null);
    });
  }, []);

  const value = useMemo(
    () => ({
      isLoggedIn,
      user,
      signIn,
      signOut,
    }),
    [isLoggedIn, user],
  );

  return <IdentityContext.Provider value={value}>{children}</IdentityContext.Provider>;
};

export const useIdentity = (): IdentityContextValue => React.useContext(IdentityContext);

export default IdentityProvider;
